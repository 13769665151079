<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2" @submit="submit">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Slug"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.slug"
              placeholder="Slug"
              name="slug"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              :value="optionsLocal.email"
              name="email"
              placeholder="Email"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="NimoTv Url"
            label-for="nimotv"
          >
            <b-form-input
              id="nimotv"
              v-model="optionsLocal.nimotv"
              placeholder="nimotv url"
              name="nimotv"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Youtube Url"
            label-for="youtube_url"
          >
            <b-form-input
              v-model="optionsLocal.youtube_url"
              placeholder="Youtube Url"
              name="youtube_channel"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Facebook Url"
            label-for="facebook_url"
          >
            <b-form-input
              id="facebook_url"
              v-model="optionsLocal.facebook_url"
              placeholder="Facebook url"
              name="facebook_url"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Designation"
            label-for="designation"
          >
            <b-form-textarea
              id="designation"
              v-model="optionsLocal.designation"
              placeholder="Designation"
              name="designation"
              cols="2"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
            />
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  watch: {
    generalData() {
      this.optionsLocal = this.generalData
    },
  },
  components: {
    BFormTextarea,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BSpinner,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      optionsLocal: {
        slug: null,
      },
      errors: null,
    }
  },
  computed: {
    // một computed getter
    isErrorString() {
      // `this` trỏ tới đối tượng vm
      return typeof this.errors === 'string'
    },
  },
  methods: {
    submit(e) {
      e.preventDefault()
      this.loading = true
      useJwt.updateAccountSetting({
        type: 'general',
        ...this.optionsLocal,
      })
        .then(({ data }) => {
          this.loading = false
          if (data.result === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update was success!',
                icon: 'BellIcon',
              },
            })
          } else {
            this.errors = data.error
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error!',
                icon: 'BellIcon',
                variant: 'danger',
                text: data.error,
              },
            })
          }
        })
    },
  },
}
</script>
